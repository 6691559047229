import { writable, derived } from "svelte/store";
export const projects = writable([]);
export const activeFilter = writable();
export const filteredProjects = derived([projects, activeFilter], ([$projects, $activeFilter]) => getFiltered($projects, $activeFilter));
export const getFiltered = (projects, filter) => {
    return projects.filter((_, i, a) => filter.value == -1
        ? a.findIndex((t) => t.nid == _.nid) == i
        : _.field_type == filter.value);
};
export const getFilteredCount = (projects, filter) => getFiltered(projects, filter).length;
